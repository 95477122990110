<template>
  <div class="page-spec-platform-editor">
    <spec-editor />
  </div>
</template>

<script lang="js">
import SpecPlatformEditorPage from './spec-platform-editor';

export default SpecPlatformEditorPage;
</script>

<style lang="scss">
@import "./spec-platform-editor";
</style>
