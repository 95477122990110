import { Component, Vue } from 'vue-property-decorator';
import SpecEditor from '../spec-merchant-editor/spec-merchant-editor.vue';

@Component({
  components: { SpecEditor }
})
class SpecPlatformEditorPage extends Vue {
  title = 'spec-platform-editor';
}

export default SpecPlatformEditorPage;
